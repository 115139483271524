








































import Vue from 'vue';

export default Vue.extend({
  name: 'AppNotifications',
  inheritAttrs: false,
  methods: {
    enhancedClose(close: () => void, action?: () => void) {
      if (action && typeof action === 'function') action();
      close();
    },
  },
});
