





































































import Vue from 'vue';
import UpdateAvailable from '@/components/UpdateAvailable.vue';
import AppNotifications from '@/components/AppNotifications.vue';
import USER_ROLE from '@/graphql/queries/UserRole.graphql';
import { USER_ROLES } from '@/utils/constants';
import { UserRoleQuery } from '@/types/schema';
import { displayName } from '../../package.json';

export default Vue.extend({
  name: 'LayoutMain',
  components: {
    UpdateAvailable,
    AppNotifications,
  },
  data() {
    return {
      user: null as UserRoleQuery['user'],
    };
  },
  computed: {
    menu(): {
      title: string;
      icon: string;
      to: { name: string };
      roles?: string[];
    }[] {
      return [
        {
          title: 'Profile',
          to: { name: 'profile' },
          icon: 'mdi-account-circle',
          roles: [USER_ROLES.ADMIN, USER_ROLES.DRIVER],
        },
        {
          title: 'Logout',
          to: { name: 'logout' },
          icon: 'mdi-exit-to-app',
        },
      ];
    },
    title(): string {
      const { title } = this.$route.meta;
      if (typeof title === 'function') return title(this.$route.params);
      return title || displayName;
    },
    actions(): { name: string; icon: string; to: { name: string } }[] {
      return this.$route.meta.actions || [];
    },
    showBackButton(): boolean {
      return this.$route.path !== '/' && this.$route.name !== 'logout';
    },
    backButtonIcon(): string {
      return this.$route.meta.backIcon || 'mdi-arrow-left';
    },
  },
  methods: {
    goBack() {
      const backToLocation = this.$route.meta.backTo ?? { name: 'home' };
      const backDate = this.$route.query.backDate;

      if (backDate) {
        this.$router.push({ ...backToLocation, params: { date: backDate } });
        return;
      }

      this.$router.push(backToLocation);
    },
    userHasRole(roles?: string[]) {
      if (!roles || !this.user) return true;
      return roles.includes(this.user.role);
    },
  },
  apollo: {
    user: {
      query: USER_ROLE,
    },
  },
});
